import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authEnvironment } from '@libs/auth/environments/environment';
import { LoginGuard } from '@libs/auth/guards/login.guard';
import { DashboardComponent } from './shared/components/dashboard/dashboard.component';

const authenticationModule = () => import('@libs/auth/auth.module').then(({ AuthModule }) => AuthModule);
const homepageModule = () => import('./modules/homepage/homepage.module').then(({ HomepageModule }) => HomepageModule);
const classModule = () => import('./modules/class/class.module').then(({ ClassModule }) => ClassModule);
const unitViewerModule = () => import('@libs/viewer/viewer.module').then(({ ViewerModule }) => ViewerModule);

const routes: Routes = [
  {
    path: '',
    data: {
      loginType: authEnvironment.studentsPlatform.loginType,
    },
    loadChildren: authenticationModule,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoginGuard],
    children: [
      { path: '', redirectTo: 'homepage', pathMatch: 'full' },
      { path: 'homepage', loadChildren: homepageModule },
      { path: 'classes', loadChildren: classModule, data: { scope: 'class' } },
      { path: 'courses', loadChildren: classModule, data: { scope: 'course' } },
      { path: 'units', loadChildren: unitViewerModule },
    ],
  },
  { path: '**', redirectTo: 'dashboard/homepage', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
