<ui-kit-top-bar
  [currentUser]="currentUser"
  [topbarUrls]="topbarUrls"
  [selectedLanguage]="selectedLanguage"
  (redirectToHome)="handleRedirectToHome()"
  (redirectToSettings)="handleRedirectToSettings()"
  (setLanguage)="updateUserLanguage($event)"
  (logout)="logout()"
></ui-kit-top-bar>

<div>
  <div class="content-container">
    <ui-kit-breadcrumbs *ngIf="breadcrumbs" [breadcrumbs]="breadcrumbs" [isLoading]="loading"></ui-kit-breadcrumbs>
  </div>
  <router-outlet></router-outlet>
</div>
